<template>
  <section class="bg-blue">
    <div class="text-container mw-60">
      <h2>
        <span class="program--title center">{{
          $t("newsletter.title-01")
        }}</span
        ><span class="program--title left">{{
          $t("newsletter.title-02")
        }}</span>
      </h2>
      <div class="grid flex-between">
        <div class="w-66"></div>
        <div class="w-33">
          <h5>
            <div v-html="$t('newsletter.subtitle')"></div>
          </h5>
        </div>
      </div>
      <div id="mc_embed_signup">
        <form
          action="https://artvr.us5.list-manage.com/subscribe/post?u=2964bab10d00bbdb3eac715ba&amp;id=e67d8c7744&amp;f_id=0019caedf0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          class="validate"
          target="_blank"
        >
          <div class="grid newsletter-container">
            <div class="w-66 w-s-100">
              <input
                id="mce-EMAIL"
                type="email"
                class="input"
                :placeholder="$t('newsletter.input.placeholder')"
              />
            </div>
            <div class="w-33 w-s-100">
              <button
                type="submit"
                name="subscribe"
                id="mc-embedded-subscribe"
                class="block"
              >
                {{ $t("newsletter.button") }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<style>
.input {
  width: calc(100% - 3vw);

  @media (max-width: 1100px) {
    width: calc(100% - 5vw);
  }
  @media (max-width: 800px) {
    width: calc(100% - 9vw);
  }
}
.newsletter-container {
  margin-top: 2rem;
}
</style>
